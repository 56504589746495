import React, { useState } from 'react'

import {
  ViewButton,
} from '@lumera/lumera-ui'

import './RequestProblemAndSolution.scss'

const RequestProblemAndSolution = () => {
  const [view, setView] = useState('problem')
  return (
    <div className="pas">
      <div className="pas-content">
        <div className="view-button-wrapper">
          <ViewButton
            selected={view === 'problem'}
            onClick={() => setView('problem')}
          >
            problem
          </ViewButton>
          <ViewButton
            selected={view === 'solution'}
            onClick={() => setView('solution')}
          >
            solution
          </ViewButton>
        </div>

        <div className="pas-list">
          {
          (view === 'problem') ? (
            <>
              <div className="pas-item">
                <h3 className="pas-item__title">
                  How do we provide smarter access to clinical services
                </h3>
              </div>
            </>
          ) : (
            <>
              <div className="pas-item">
                <h3 className="pas-item__title">
                  Your Request for Assistance form is now online
                </h3>
              </div>
            </>
          )
          }
        </div>
      </div>
    </div>
  )
}


export default RequestProblemAndSolution
