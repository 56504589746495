import { createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
// import { createEpicMiddleware } from 'redux-observable'
import { composeWithDevTools } from 'redux-devtools-extension'
import promise from 'redux-promise-middleware'
import rootReducer from './reducers/index'
// import rootEpic from '../epics'

import history from './history'

// const epicMiddleware = createEpicMiddleware()
const middleware = applyMiddleware(promise, thunkMiddleware)

const enhancers = process.env.NODE_ENV === 'production'
  ? middleware
  : composeWithDevTools(middleware)

const store = createStore(rootReducer, {}, enhancers)
// epicMiddleware.run(rootEpic)

let prevLocation = {}

history.listen((location) => {
  const pathChanged = prevLocation.pathname !== location.pathname
  const hashChanged = prevLocation.hash !== location.hash
  if (pathChanged || hashChanged) window.scrollTo(0, 0)
  prevLocation = location
})
export default store
