import React, { useState } from 'react'

import {
  ToggleComponent,
  ToggleItem,
} from '@lumera/lumera-ui'

import './BookFeaturesAndBenefits.scss'

const BookFeaturesAndBenefits = () => {
  const [view, setView] = useState('benefits')
  return (
    <div className="fabs">
      <div className="fab-content">
        <ToggleComponent
          onClickLeft={() => setView('benefits')}
          onClickRight={() => setView('features')}
          leftButtonText="BENEFITS"
          rightButtonText="FEATURES"
          selectedLeft={view === 'benefits'}
          selectedRight={view === 'features'}
        />
        <div className="fab-list">
          {
          (view === 'benefits')
            ? (
              <>
                <ToggleItem
                  header="Reduced waiting times"
                  paragraph="Tackling wasted slots &amp; Did Not Attends"
                  ariaLabel="hourglass"
                />
                <ToggleItem
                  header="Improved patient experience"
                  paragraph="Pick a time, date &amp; channel that works best"
                  ariaLabel="star"
                />
                <ToggleItem
                  header="Reduced staff workload"
                  paragraph="No more to’ing &amp; fro’ing to find suitable dates"
                  ariaLabel="target"
                />
                <ToggleItem
                  header="Understand service supply &amp; demand"
                  paragraph="Insights to reshape supply to meet demand spikes"
                  ariaLabel="scales"
                />
                <ToggleItem
                  header="Embraces video conversations"
                  paragraph="Supports adoption of Attend Anywhere"
                  ariaLabel="conversation"
                />
              </>
            ) : (
              <>
                <ToggleItem
                  header="Online appointment booking"
                  paragraph="Choose from eligible slots, even last-minute cancellations"
                  ariaLabel="drums"
                />
                <ToggleItem
                  header="Therapist &amp; service schedules"
                  paragraph="Personally update clinical availability in seconds"
                  ariaLabel="diaries"
                />
                <ToggleItem
                  header="Analytics for service insights"
                  paragraph="Learn insights, spot patterns, inform decision-making"
                  ariaLabel="idea"
                />
                <ToggleItem
                  header="Automated reminders"
                  paragraph="Keep informed &amp; reduce DNAs through texts & emails"
                  ariaLabel="bell"
                />
                <ToggleItem
                  header="Family/teacher view"
                  paragraph="Manage appointments for all children in one place"
                  ariaLabel="tutor-cap"
                />
              </>
            )
          }
        </div>
      </div>
    </div>
  )
}

export default BookFeaturesAndBenefits
