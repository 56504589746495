import React, { useState } from 'react'

import {
  ToggleComponent,
  ToggleItem,
} from '@lumera/lumera-ui'

import './RequestFeaturesAndBenefits.scss'

const RequestFeaturesAndBenefits = () => {
  const [view, setView] = useState('benefits')
  return (
    <div className="fabs">
      <div className="fabs__content">
        <ToggleComponent
          onClickLeft={() => setView('benefits')}
          onClickRight={() => setView('features')}
          leftButtonText="BENEFITS"
          rightButtonText="FEATURES"
          selectedLeft={view === 'benefits'}
          selectedRight={view === 'features'}
        />
        <div className="fabs__content fabs__content__list">
          {
          (view === 'benefits')
            ? (
              <>
                <ToggleItem
                  header="Adapted to your environment"
                  paragraph="Plays well with Trakcare, Morse &amp; more"
                  ariaLabel="dna"
                />
                <ToggleItem
                  header="Enables online entry to services"
                  paragraph="For early intervention, prevention &amp; signposting"
                  ariaLabel="signposting"
                />
                <ToggleItem
                  header="Focussed on the community"
                  paragraph="Designed &amp; built around community services"
                  ariaLabel="strong"
                />
                <ToggleItem
                  header="Delivered at scale"
                  paragraph="Cloud-native, not just cloud-based"
                  ariaLabel="cloud"
                />
                <ToggleItem
                  header="Supporting digital-first mentality"
                  paragraph="Digital data flow, not papers in disarray"
                  ariaLabel="first"
                />
              </>
            ) : (
              <>
                <ToggleItem
                  header="Online Request for Assistance"
                  paragraph="Save time by reducing data re-entry"
                  ariaLabel="surfing"
                />
                <ToggleItem
                  header="Accessible &amp; inclusive for all"
                  paragraph="By phone, tablet, computer. 24/7—365"
                  ariaLabel="earth"
                />
                <ToggleItem
                  header="Targeted videos for users"
                  paragraph="Set expectations, provide reassurance &amp; education"
                  ariaLabel="target"
                />
                <ToggleItem
                  header="Seamlessly aligned entry point"
                  paragraph="Access &amp; submit online, by paper or email"
                  ariaLabel="railway-track"
                />
                <ToggleItem
                  header="Single access point for all"
                  paragraph="For parents &amp; professionals alike"
                  ariaLabel="droplet"
                />
              </>
            )
          }
        </div>
      </div>
    </div>
  )
}

export default RequestFeaturesAndBenefits
