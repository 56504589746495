import React from 'react'

import './Landing.scss'
// import IconLumeraLogo from '../../components/IconLumeraLogo/IconLumeraLogo'
// import PrimaryButton from '../../components/PrimaryButton'
// import IconPartneredLogos from '../../components/IconPartneredLogos/IconPartneredLogos'
// import FeaturesAndBenefits from '../../components/FeaturesAndBenefits/FeaturesAndBenefits'
import RequestFeaturesAndBenefits from '../../components/RequestFeaturesAndBenefits/RequestFeaturesAndBenefits'
import BookFeaturesAndBenefits from '../../components/BookFeaturesAndBenefits/BookFeaturesAndBenefits'
import AdamFeaturesAndBenefits from '../../components/AdamFeaturesAndBenefits/AdamFeaturesAndBenefits'
import BookProblemAndSolution from '../../components/BookProblemAndSolution/BookProblemAndSolution'
import AdamProblemAndSolution from '../../components/AdamProblemAndSolution/AdamProblemAndSolution'
import SectionTitle from '../../components/SectionTitle/SectionTitle'
// import IconCivTech from '../../components/IconCivTech/IconCivTech'
import RequestProblemAndSolution from '../../components/RequestProblemAndSolution/RequestProblemAndSolution'
// import HeroDescriptionField from '../../components/HeroDescriptionField/HeroDescriptionField'
// import RFAdemo from '../../videos/rfa-demo.mp4'
import LogoContainer from '../../components/LogoContainer/LogoContainer'

const Landing = () => (
  <>
    <section className="hero">
      <div className="hero-container">
        <div className="hero-container__header">
          Building products for
          {' '}
          <br />
          {' '}
          community health &amp; care
          {' '}
        </div>
        <div className="hero-container__content">
          <div className="hero-container__content__item">
            <div className="hero-container__content__item">
              <div className="hero-container__content__item header">
                5 people.
              </div>
              <div className="hero-container__content__item body">
                Building at scale
                <span role="img" aria-label="building-crane">🏗</span>
              </div>
            </div>
          </div>
          <div className="hero-container__content__item">
            <div className="hero-container__content__item">
              <div className="hero-container__content__item header">
                2 live products.
              </div>
              <div className="hero-container__content__item body">
                Soon to be 3
                <span role="img" aria-label="confetti-ball">🎊</span>
              </div>
            </div>
          </div>
          <div className="hero-container__content__item">
            <div className="hero-container__content__item header">
              2 years.
            </div>
            <div className="hero-container__content__item body">
              Working with NHS
              <span role="img" aria-label="hand-shake">🤝</span>
            </div>
          </div>
          <div className="hero-container__content__item">
            <div className="hero-container__content__item header">
              Fancy a chat?
            </div>
            <div className="hero-container__content__item body">
              <a href="mailto:info@lumerahealth.com">
                Get in touch
                {/* <span className="contact-hand" role="img" aria-label="waving-hand">👋</span> */}
              </a>
              <span className="header-get-in-touch">↗︎</span>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="story-and-logos">
      <div className="story-and-logos-container">
        <div className="story-and-logos-container__content">
          <span className="hero-civtech">
            Born out of
            {' '}
            <a
              href="https://civtech.atlassian.net/wiki/spaces/C4/overview?mode=global"
              target="_"
              className="story-and-logos-container__content__civ-tech"
            >
              CivTech.
            </a>
            <span className="story-and-logos-container__content__tab-arrow">↗︎</span>
          </span>
          <span className="hero-middle-container">
            <span className="hero-middle-container__start">
              We’re an Edinburgh-based start-up,
            </span>
            <span className="hero-middle-container__end">
              here to change community health &amp; care.
            </span>
          </span>
          <span className="hero-powered">
            Powered by helping people and creating technology for good.
          </span>
        </div>
        <LogoContainer />
      </div>
    </section>


    <section className="request-for-assistance">
      <SectionTitle boldText="VIA:" text="Request" />
      <div className="product-container">
        <div className="product-container__content">
          <div className="product-container__content__problem-solution">
            <RequestProblemAndSolution />
          </div>
          <div className="product-container__content__benefits-features">
            <RequestFeaturesAndBenefits />
          </div>
          <div className="product-container__content__video">
            <video className="product-container__content__video__item" autoPlay loop muted playsInline>
              <source src="https://pathfinderb2cui.blob.core.windows.net/b2c-ui/demo-rfa.mp4" type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
    </section>

    <section className="appointment-booking">
      <SectionTitle boldText="VIA:" text="Book" />
      <div className="product-container">
        <div className="product-container__content">
          <div className="product-container__content__problem-solution">
            <BookProblemAndSolution />
          </div>
          <div className="product-container__content__benefits-features">
            <BookFeaturesAndBenefits />
          </div>
          <div className="product-container__content__video">
            <video className="product-container__content__video__item" autoPlay loop muted playsInline>
              <source src="https://pathfinderb2cui.blob.core.windows.net/b2c-ui/book-demo.mp4" type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
    </section>

    <section className="adam">
      <SectionTitle boldText="Adam:" text=" About Digital & Me" />
      <div className="product-container">
        <div className="product-container__content">
          <div className="product-container__content__problem-solution">
            <AdamProblemAndSolution />
          </div>
          <div className="product-container__content__benefits-features">
            <AdamFeaturesAndBenefits />
          </div>
          <div className="product-container__content__video__adam-video">
            <video className="product-container__content__video__item__adam-item" autoPlay loop muted playsInline>
              <source src="https://pathfinderb2cui.blob.core.windows.net/b2c-ui/book-demo.mp4" type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
    </section>

    <section className="footer">
      <footer>
        <span role="img" aria-label="postbox">📮</span>
        {/* <br /> */}
        <p className="lets-chat">Let&apos;s chat!</p>
        {/* <br /> */}
        <p className="hear-from-you">We&apos;d love to hear from you</p>
        {/* <br /> */}
        <a className="contact-us" href="mailto:info@lumerahealth.com">Contact us</a>
      </footer>
    </section>
  </>
)

export default Landing
