import React from 'react'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'

import store from './store'
import history from './history'
import MainRouter from './routes/MainRouter'

import './base.scss'

const App = () => (
  <Provider store={store}>
    <Router history={history}>
      <MainRouter history={history} store={store} />
    </Router>
  </Provider>
)

export default App
