import React from 'react'
import { Switch, Route } from 'react-router-dom'
// import PropTypes from 'prop-types'

import Landing from '../../containers/Landing/Landing'

const MainRouter = () => (
  <>
    {/* <Route history={history} path="/" component={NavBar} /> */}
    <Switch>
      <Route path="/" component={Landing} />
    </Switch>
  </>
)

// MainRouter.propTypes = {
//   history: PropTypes.object.isRequired,
// }

export default MainRouter
