import React from 'react'
// import PropTypes from 'prop-types'
import './LogoContainer.scss'

const LogoContainer = () => (
  <div className="logo-container">
    <div className="logo-container__sliding-background" />
  </div>
)

export default LogoContainer
