import React, { useState } from 'react'

import {
  ToggleComponent,
  ToggleItem,
} from '@lumera/lumera-ui'

import './AdamFeaturesAndBenefits.scss'

const BookFeaturesAndBenefits = () => {
  const [view, setView] = useState('benefits')
  return (
    <div className="fabs">
      <div className="fab-content">
        <ToggleComponent
          onClickLeft={() => setView('benefits')}
          onClickRight={() => setView('features')}
          leftButtonText="BENEFITS"
          rightButtonText="FEATURES"
          selectedLeft={view === 'benefits'}
          selectedRight={view === 'features'}
        />
        <div className="fab-list">
          {
          (view === 'benefits')
            ? (
              <>
                <ToggleItem
                  header="Savings of over £14m per year"
                  paragraph="Helps citizens age-in-place longer"
                  ariaLabel="money"
                />
                <ToggleItem
                  header="Helps citizens engage in local community"
                  paragraph="Stay connected to the people &amp; things they love"
                  ariaLabel="community"
                />
                <ToggleItem
                  header="Facilitates staff, family &amp; citizen education"
                  paragraph="Fills gaps in knowledge of fast-changing digital products"
                  ariaLabel="tutor-cap"
                />
                <ToggleItem
                  header="Helps citizens achieve their personal outcomes"
                  paragraph="From staying connected to feeling safe & secure"
                  ariaLabel="strong"
                />
                <ToggleItem
                  header="Encourages early intervention before crisis point"
                  paragraph="Opportunity to mitigate against risks like falling"
                  ariaLabel="signposting"
                />
              </>
            ) : (
              <>
                <ToggleItem
                  header="Finds products to match your needs"
                  paragraph="Questionnaire built around what matters to the citizen"
                  ariaLabel="star"
                />
                <ToggleItem
                  header="Accessible & inclusive for all"
                  paragraph="By phone, tablet, computer. 24/7—365"
                  ariaLabel="earth"
                />
                <ToggleItem
                  header="Tried &amp; tested digital products"
                  paragraph="Curated by Alzheimer Scotland Dementia Circles"
                  ariaLabel="target"
                />
                <ToggleItem
                  header="AI-based recommendations engine"
                  paragraph="Built around a holistic view of needs, challenges & goals"
                  ariaLabel="dna"
                />
                <ToggleItem
                  header="Usable by a wide range of users"
                  paragraph="For citizens with dementia, carers, link workers, dementia advisors etc"
                  ariaLabel="conversation"
                />
              </>
            )
          }
        </div>
      </div>
    </div>
  )
}

export default BookFeaturesAndBenefits
