import React from 'react'
import PropTypes from 'prop-types'
import './SectionTitle.scss'


const SectionTitle = ({ boldText, text }) => (
  <div className="section-title">
    <strong>{boldText}</strong>
    {' '}
    {text}
  </div>
  // <div className="section-title">
  //   <div className="section-title__cta">
  //     {boldText}
  //   </div>
  //   <div className="section-title__page-title">
  //     {text}
  //   </div>
  // </div>
)

SectionTitle.propTypes = {
  boldText: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}

export default SectionTitle
